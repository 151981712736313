const setLs = (key, data) => localStorage.setItem(key, JSON.stringify(data));
const getLs = (key) => JSON.parse(localStorage.getItem(key));
const setSs = (key, data) => sessionStorage.setItem(key, JSON.stringify(data));
const getSs = (key) => JSON.parse(sessionStorage.getItem(key));


const urlAddLang = url => {
    let lang = '';
    switch (location.pathname.substring(0, 4)) {
        case '/en/':
            lang = '/en/';
            break;
        case '/ru/':
            lang = '/ru/';
            break;
        default:
            lang = '/';
            break;
    }
    return lang + (url.indexOf('/') === 0 ? url.replace(/^[/]+/, '') : url);
};

const getFormData = object => Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
}, new FormData());

const postBody = data => {
    if (data.nodeType === Node.ELEMENT_NODE && data.nodeName === 'FORM') {
        return new FormData(data);
    } else if (data instanceof FormData) {
        return data;
    } else if (typeof data === 'object') {
        return getFormData(data);
    } else if (typeof data === 'string' && data.length) {
        return data;
    }
    return '';
};

const PRELOADER = {
    show: function () {
        document.getElementById('preloader').classList.add('show');
    },
    hide: function () {
        document.getElementById('preloader').classList.remove('show');
    }
};

async function ajaxGetData(data = {}, url = '', json = true) {
    let params = '';
    if (typeof data === 'object') {
        params = '?' + new URLSearchParams(data)
    }

    const response = await fetch((url ? url : '/index.php') + params, {
        method: 'GET',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return json ? await response.json() : await response.text();
}

async function ajaxPostData(url = '', data = {}, json = true) {
    if (url === '') {
        url = '/';
    }
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            /*'Content-Type': 'application/json',*/
            'X-Requested-With': 'XMLHttpRequest',
            //'Content-Type': 'application/x-www-form-urlencoded',
        },

        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *client
        body: postBody(data) // body data type must match "Content-Type" header
    });
    return json ? await response.json() : await response.text(); // parses JSON response into native JavaScript objects
}

const getOffset = el => {
    let result = {
        top: 0,
        left: 0
    };
    if (typeof el === 'string') {
        el = document.querySelector(el);
    }

    if (el) {
        el = NodeList.prototype.isPrototypeOf(el) ? el[0] : el;
        let rect = el.getBoundingClientRect();
        let win = el.ownerDocument.defaultView;
        result = {
            top: rect.top + win.pageYOffset,
            left: rect.left + win.pageXOffset
        };
    }
    return result;
};

/**
 * Установка нескольких событий на элемент(ы)
 * @param el
 * @param evt
 * @param fn
 * @returns {boolean}
 */
function addListenerMulti(el, evt, fn) {
    if (typeof el === 'string') {
        el = document.querySelectorAll(el);
    }

    if (!el) {
        return false;
    }

    if (typeof evt === 'string') {
        evt = evt.split(' ');
    }

    el.forEach(n => {
        evt.forEach(e => n.addEventListener(e, fn, false));
    })

}

/**
 * Получение родственных элеменов по селектору или все, кроме целевого элемента
 * @param el
 * @param s
 * @returns {*[]}
 */
function getSiblings(el, s) {

    return el ? [...el.parentNode.querySelectorAll(':scope > ' + (s || '*'))]
        .filter(n => n !== el) : false;
    /*
    let _parent = el.parentNode;
    return [..._parent.querySelectorAll(':scope > ' + (s || '*'))]
        .filter(n => n !== el);
    */
}

// Анимация лого при старте сессии или по прошествию 5 минут (300 сек)
const startTimeSeconds = (new Date().getTime()) / 1000;
const logoAnimated = getSs('logo_anim_time');
if (!logoAnimated || startTimeSeconds - logoAnimated > 300) {
    document.querySelector('#header .navbar-brand').classList.add('animating');
    setSs('logo_anim_time', startTimeSeconds);
}


// -- -- Поиск в шапке
const headerNavbarSide = document.getElementById('header_navbar_side');
const btnOpenSearch = [...document.querySelectorAll('.btn_open_search')];
const serachFormDesktop = document.querySelector('.nav_static .search_form');
document.addEventListener('click', e => {
    let curSearchForm = document.querySelector('.search_form.show');
    if (curSearchForm && !e.target.closest('.search_form')) {
        e.stopPropagation();
        curSearchForm.classList.remove('show');
        curSearchForm.reset();
        curSearchForm.querySelector('.search_result').classList.remove('d-flex');

    }
});

btnOpenSearch.forEach(btn => {
    btn.addEventListener('click', e => {
        e.stopPropagation();
        //headerNavbarSide.classList.remove('show');
        headerNavbarSide.querySelector('.btn-close').click();
        let _form = document.getElementById(btn.dataset.target);
        _form.classList.add('show');
        _form.querySelector('[name=search]')?.focus();

    });
});

// Закрытие формы поиска
document.getElementById('close_search_form')?.addEventListener('click', e => {
    e.currentTarget.form.classList.remove('show');
    //headerNavbarSide.classList.add('show');
});

document.querySelectorAll('.search_form').forEach(form => {
    form.querySelector('[name=search]').addEventListener('input', e => {
        let q = e.target.value.replace(/\s+$/);
        let searchResult = e.target.nextElementSibling;
        if (q.length > 2) {
            ajaxGetData({
                route: 'product/product/search',
                q: e.target.value.replace(/\s+$/),
            }).then((json) => {
                if (json.length) {
                    // TODO раскрыть блок вывода результата
                    searchResult.innerHTML = json.map(el => `<a href="${el.href}" class="d-flex py-2 text-decoration-none border-bottom">
                      <div class="flex-shrink-0">
                        <img src="${el.image}" class="img-thumbnail" alt="">
                      </div>
                      <div class="flex-grow-1 ms-3">
                        <div class="h6 mb-2">${el.name}</div>
                        <div class="d-flex justify-content-between small">
                          <b>${el.price}</b>
                          <span class="small">${el.model}</span>
                        </div>
                      </div>
                    </a>`).join``;
                    searchResult.classList.add('d-flex');
                } else {
                    searchResult.innerHTML = 'Нічого не знайдено';
                }

            });
        } else {
            searchResult.textContent = '';
            searchResult.classList.remove('d-flex');
        }

    });
});

// -- Добавление в Избранное/Закладки
let wishlistBtn = [...document.querySelectorAll('.btn_wishlist')];
if (wishlistBtn.length) {
    wishlistBtn.forEach(btn => {
        btn.addEventListener('click', e => {
            e.preventDefault();
            ajaxPostData('/index.php?route=account/wishlist/add', {
                product_id: e.currentTarget.dataset.id
            }).then(json => {
                if (json) {
                    Swal.fire({
                        html: json.message,
                        icon: json.icon,
                        showCancelButton: false,
                    });
                    if (json.icon === 'success') {
                        btn.classList.add('active');
                    }

                }
            });
        });
    });

    // Отмечаем на странице добавленные в избранное товары
    ajaxGetData('', '/index.php?route=product/product/getWishlist').then(json => {
        if (Array.isArray(json)) {
            json.forEach(id => wishlistBtn.filter(el => el.dataset.id === id)[0]?.classList.add('active'));
        }
    });

}

// -- Превью корзины в шапке
const BTNCART = document.getElementById('btn_cart');
const dropdownCartPreview = document.querySelector('.dropdown_cart_preview');
const getCartInfo = () => {
    if (dropdownCartPreview) {
        ajaxGetData({
            route: 'common/cart/info',
        }, '', false).then(html => {
            dropdownCartPreview.innerHTML = html;
        });
    }
};
getCartInfo();

// Удаление из корзины
document.addEventListener('click', e => {
    let $that = e.target.closest('.remove_cart_item');
    if ($that) {
        e.stopPropagation();
        ajaxPostData('/index.php?route=checkout/cart/remove', {
            key: $that.dataset.key
        }).then(json => {
            if (json.success) {
                let t = document.getElementById('cart_total');
                if (json.total) {
                    t.textContent = json.total;
                    t.style.display = 'block';
                } else {
                    t.textContent = '';
                    t.style.display = 'none';
                }
                getCartInfo();

                if ($that.closest('.form_cart_edit')) {
                    location.reload(true);
                } else {
                    BTNCART.click();
                }

            }

        });

    }
});

// Очистка полей пароля от лишних пробелов
document.querySelectorAll('input[type=password]').forEach(inp => {
    inp.addEventListener('blur', e => {
        inp.value = inp.value.trim();
    });
});

// Вкл./Выкл. видимость пароля
document.querySelectorAll('.toggle_show_password').forEach(btn => {
    let passInp = document.querySelector(btn.dataset.password);
    if (passInp) {
        btn.addEventListener('click', () => {
            if (passInp.getAttribute('type') === 'password') {
                passInp.setAttribute('type', 'text');
                btn.classList.remove('fa-light', 'fa-eye-slash');
                btn.classList.add('fa-solid', 'fa-eye');
            } else {
                passInp.setAttribute('type', 'password');
                btn.classList.remove('fa-solid', 'fa-eye');
                btn.classList.add('fa-light', 'fa-eye-slash');
            }

        });
    }
});
